/// breakpoint shortcut
/// @param $min - A string for selection teh breakpoint size min
/// @param $bpw-min - An Object of available breakpoints name and value (small: 35em, medium:50em,...)
/// @param $max - A string for selection teh breakpoint size max
/// @param $max-width - An Object of available breakpoints name and value (small: 35em, medium:50em,...)
/// @example
///   font-size: 12px
///   +bp(medium, $bp-m)
///      font-size: 14px

@mixin bp($min, $min-width, $max: null, $max-width: null, $max-limit: large, $use-mediaqueries: true)
  $min-value: map-get($min-width, $min)
  $max-value: null
  @if $max-width
    $max-value: map-get($max-width, $max)
  @if $min-value
    @if $max-value
      @if $use-mediaqueries
        @media (min-width: $min-value) and (max-width: $max-value)
          @content
      @else if $max-value > map-get($max-width, $max-limit)
        @content
    @else
      @if $use-mediaqueries
        @media (min-width: $min-value)
          @content
      @else if $min-value <= map-get($min-width, $max-limit)
        @content
  @else if $max-value
    @if $use-mediaqueries
      @media (max-width: $max-value)
        @content
    @else if $max-value > map-get($max-width, $max-limit)
      @content


$bpw-min: (xs: 375px, sm: 414px, md: 768px, lg: 1024px, xl: 1280px, xxl: 1920px, hg: 2560px, mhg: 3440px)
$bpw-max: (xs: 374px, sm: 413px, md: 767px, lg: 1023px, xl: 1279px, xxl: 1919px, hg: 2559px, mhg: 3439px)

@mixin bpw($min, $max: null, $max-limit: xl)
  $min-value: map-get($bpw-min, $min)
  @if $min-value == null
    $min-value: $min
  $max-value: map-get($bpw-max, $max)
  @if $min-value
    @if $max-value
      @media (min-width: $min-value) and (max-width: $max-value)
        @content
    @else
      @media (min-width: $min-value)
        @content
  @else if $max-value
    @media (max-width: $max-value)
      @content


$bph-min: (xs: 667px, sm: 736px, md: 812px, lg: 1024px, xl: 1366px, xxl: 1440px)
$bph-max: (xs: 666px, sm: 735px, md: 811px, lg: 1023px, xl: 1365px, xxl: 1439px)

@mixin bph($min, $max: null, $max-limit: xl)
  $min-value: map-get($bph-min, $min)
  @if $min-value == null
    $min-value: $min
  $max-value: map-get($bph-max, $max)
  @if $min-value
    @if $max-value
      @media (min-height: $min-value) and (max-height: $max-value)
        @content
    @else
      @media (min-height: $min-value)
        @content
  @else if $max-value
    @media (max-height: $max-value)
      @content
